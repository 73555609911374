import './App.css';
import { Loanching, ConfiguratorContextComponent } from './components/configurator';
// eslint-disable-next-line
import { GlobalStyle } from './GlobalStyle';
import { LabelContextComponent } from './core/label.context';


function App(props: any) {
	
  return (
  	<LabelContextComponent channel={props.channel}>  	
	  	<ConfiguratorContextComponent technologyStyle={props.technologyStyle} technology={props.technology}>
	    	<Loanching/>
	  	</ConfiguratorContextComponent>
  	</LabelContextComponent>
  );
}


export default App;

