import * as React from "react";
import ReactDOM from "react-dom";
//import {configuratorSetting} from './configuratorSetting';
import Slider from "react-slick";
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { LabelContext } from "../core/label.context";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import "./configurator.css"; 

import {ItemStyle, ConfiguratorStyle, ProductDetailStyle} from "./configurator.style"; 
//import { Props } from "../App";




export const ConfiguratorContext = React.createContext<any>(null!);

export const ConfiguratorContextComponent = (props: any) => {
	const {t, configuratorSetting} = React.useContext(LabelContext);
	 
	const configurationRootKey = props.technology;
	const [show, setShow] = React.useState(false);	

	const [step1, setStep1] 	= React.useState<string>();
	const [step2, setStep2] 	= React.useState<string>();
	const [step3, setStep3] 	= React.useState<string>();
	const [step4, setStep4] 	= React.useState<string>();
	const [step5, setStep5] 	= React.useState<string>();
	


	const [step, setStep] = React.useState(0);
	const [prevStep, setPrevStep] = React.useState(0);

	const [items, setItems] = React.useState<any>([]);
	const [selectedProduct, setSelectedProduct] = React.useState<any>();

	const [stepQueue, setStepQueue] = React.useState<any>([]);
	const [checkChange, setCheckChange] = React.useState<number>(0);
	const [titleStep, setTitleStep] = React.useState<number>(0);

	
	const setPreviousState = () => {
		
    	let prevStep = stepQueue[step];

		if(prevStep === 1){ 
			setStep1(""); 
			setStep2(""); setStep3(""); setStep4(""); setStep5(""); }
		if(prevStep === 2){ setStep2(""); setStep3(""); setStep4(""); setStep5(""); }
		if(prevStep === 3){ setStep3(""); setStep4(""); setStep5(""); }
		if(prevStep === 4){ setStep4(""); setStep5(""); }
		if(prevStep === 5){ setStep5(""); }
		
    }


	const getKeyIfIsOnlyItem = (items: any[]) => {
		let onlyItemKey = undefined;
		if(items?.length==1){
					
			onlyItemKey = Object.keys(items[0])[0]
		}
		return onlyItemKey;
	}

	React.useEffect(() => {
		let currentStep = 0;
		if(show)  { currentStep = 1 }
		if(step1 && step2 && step3 && step4 && step5) {currentStep = 6}
		if(step1 && step2 && step3 && step4 && !step5) {currentStep = 5}
		if(step1 && step2 && step3 && !step4 && !step5) {currentStep = 4}
		if(step1 && step2 && !step3 && !step4 && !step5) {currentStep = 3}
		if(step1 && !step2 && !step3 && !step4 && !step5) {currentStep = 2}
		

		let items:any = []
		let nextItems:any = []
		let node1Selected:any;
		let node2Selected: any;
		let selectedProduct:any;
		let configurationRoot = configuratorSetting[configurationRootKey]["configurator"];
		let titleStep = configuratorSetting[configurationRootKey]?.title_section

		let parentDetailCaption;
		let parentDetailTitle;
		let parentDetailDescription;
		let parentDetailInfo;

		let currentProductAttribute:any;
		
		let keyNode1:string;
		let keyNode2:string;

		if(currentStep==1){
			// Avvio, nessuna selezione
			items = configurationRoot.map( (item:any) =>  item )
			let onlyTecnology = getKeyIfIsOnlyItem(items)
			if(onlyTecnology){				
				setStep1(onlyTecnology)
			}
		}
		if(step1){
			// Selazionata, step1
			node1Selected = configurationRoot.find( (item:any) => Object.keys(item)[0]==step1)
			if(node1Selected){
				
				keyNode1 = Object.keys(node1Selected)[0]
				titleStep = node1Selected[keyNode1]?.title_section;
				nextItems = node1Selected[keyNode1]?.step1;
			
				parentDetailCaption 	= node1Selected[keyNode1]?.detail_caption
				parentDetailTitle 		= node1Selected[keyNode1]?.detail_title
				parentDetailDescription = node1Selected[keyNode1]?.detail_description
				parentDetailInfo 		= node1Selected[keyNode1]?.detail_info

				/*
				currentProductAttribute = { 
					detail_caption: node1Selected[keyNode1]?.detail_caption,
					detail_title: node1Selected[keyNode1]?.detail_title,
					detail_description: node1Selected[keyNode1]?.detail_description,
				}

				parentProductAttribute = { ...parentProductAttribute , ...currentProductAttribute }*/

				currentStep = 1;
				if(nextItems?.length>1){
					currentStep = 2;
					items = nextItems;
				}else{
					selectedProduct = node1Selected[keyNode1];								
				}
			
				if(step2){
					node2Selected = node1Selected[keyNode1]?.step1.find( (item:any) => Object.keys(item)[0]==step2)
					if(node2Selected){
						currentStep = 2;
						keyNode2 = Object.keys(node2Selected)[0]
						titleStep = node2Selected[keyNode2]?.title_section;
						nextItems = node2Selected[keyNode2]?.step2;

						parentDetailCaption 	= node2Selected[keyNode2]?.detail_caption || parentDetailCaption
						parentDetailTitle 		= node2Selected[keyNode2]?.detail_title || parentDetailTitle
						parentDetailDescription = node2Selected[keyNode2]?.detail_description || parentDetailDescription
						parentDetailInfo 		= node2Selected[keyNode2]?.detail_info || parentDetailInfo

						if(nextItems?.length>1){
							currentStep = 3;
							items = nextItems;
						}else{
							selectedProduct = node2Selected[keyNode2];								
						}


						if(step3){	
							let node3Selected = node2Selected[keyNode2]?.step2.find( (item:any) => Object.keys(item)[0]==step3)
							let keyNode3 = Object.keys(node3Selected)[0]
							if(node3Selected[step3]?.title_section){
								titleStep = node3Selected[step3]?.title_section							
							}
							nextItems = node3Selected[step3]?.step3	

							parentDetailCaption 	= node3Selected[keyNode3]?.detail_caption || parentDetailCaption
							parentDetailTitle 		= node3Selected[keyNode3]?.detail_title || parentDetailTitle
							parentDetailDescription = node3Selected[keyNode3]?.detail_description || parentDetailDescription
							parentDetailInfo 		= node3Selected[keyNode3]?.detail_info || parentDetailInfo


							currentStep = 3;
							if(nextItems?.length>1){
								currentStep = 4;
								items = nextItems;
							}else{
								selectedProduct = node3Selected[step3];								
							}

							if(step4){

								let node4Selected = node3Selected[keyNode3]?.step3.find( (item:any) => Object.keys(item)[0]==step4)
								let keyNode4 = Object.keys(node4Selected)[0]
								titleStep = node4Selected[step4]?.title_section							
								nextItems = node4Selected[step4]?.step4	
								
								parentDetailCaption 	= node4Selected[keyNode4]?.detail_caption || parentDetailCaption
								parentDetailTitle 		= node4Selected[keyNode4]?.detail_title || parentDetailTitle
								parentDetailDescription = node4Selected[keyNode4]?.detail_description || parentDetailDescription
								parentDetailInfo 		= node4Selected[keyNode4]?.detail_info || parentDetailInfo

								currentStep = 4;																
								if(nextItems?.length>1){
									currentStep = 5;
									items = nextItems;
								}else{
									selectedProduct = node4Selected[step4];								
								}
								if(step5){
									let node5Selected = node4Selected[keyNode4]?.step4.find( (item:any) => Object.keys(item)[0]==step5)
									//titleStep = node4Selected[step4]?.title_section							
									//nextItems = node4Selected[step4]?.step3	
									currentStep = 5;
									
									selectedProduct = node5Selected[step5];								
										/*
									if(nextItems?.length>1){
										currentStep = 6;
										items = nextItems;
									}else{
									}*/
								}
							}

						}
					}				
				}
			}
		}
		

		
		setStep(currentStep);
		if(items?.length>1){
			setItems(items)
		}
		
		if(selectedProduct){
			selectedProduct.detail_caption = selectedProduct.detail_caption || parentDetailCaption
			selectedProduct.detail_info = selectedProduct.detail_info || parentDetailInfo
			selectedProduct.detail_description = selectedProduct.detail_description || parentDetailDescription
			selectedProduct.detail_title = selectedProduct.detail_title || parentDetailTitle
		}
		console.log(selectedProduct)
		setSelectedProduct(selectedProduct);
		setTitleStep(titleStep);
		
	}, [step1,step2,step3,show,step4,checkChange])

	return (
	    <ConfiguratorContext.Provider value={{
	    	technologyStyle: props.technologyStyle,
	    	step: step,
	    	setStep: setStep,
	    	setStep1: setStep1,
	    	setStep2: setStep2,
	    	setStep3: setStep3,
	    	setStep4: setStep4,
	    	setStep5: setStep5,
	    	step1: step1,	    	
	    	step2: step2,
	    	step3: step3,
	    	items:items,
	    	selectedProduct: selectedProduct,
	    	technologyRoot: configurationRootKey,
	    	setShow: setShow,
	    	show:show,
	    	setPrevStep: setPrevStep,
	    	prevStep: prevStep,
	    	setPreviousState: setPreviousState,
	    	stepQueue: stepQueue,
	    	setStepQueue: setStepQueue,
	    	setSelectedProduct: setSelectedProduct,
	    	setCheckChange:setCheckChange,
	    	checkChange:checkChange,
	    	titleStep: titleStep
	    }}>
	    	{props.children}
	    </ConfiguratorContext.Provider>
    )
}

export const Loanching = (props: any) => {
	const container = document.getElementById("configuratorContainer");
	const { step, setStep, setShow, show, items, selectedProduct, technologyStyle, technologyRoot,
	setStep4, setStep2, setStep1, setStep3, setStep5 } = React.useContext(ConfiguratorContext);
	
	const {t, configuratorSetting} = React.useContext(LabelContext);
	const handleClose = () => { setShow(false); setStep4(); setStep2(); setStep1(); setStep3(); };
  	const handleShow = () => setShow(true);

  	
  	let configurationRoot = configuratorSetting[technologyRoot];
  	let labels = configurationRoot["labels"]
	//{t("ecommerce.common.related.title")}
	if (!container)
        return null;
    else
		return ReactDOM.createPortal( 
			<>
		      <Button variant="cta-btn cta-brown" onClick={() => {handleShow(); setShow(true)}}>
		        {labels["btn"]["open"]}
		      </Button>

		      <Modal
		        show={show}
		        onHide={handleClose}
		        backdrop="static"
		        keyboard={false}
		        fullscreen={true}
				className= {"configurator-modal " + technologyStyle}		        
		      >
		        <Modal.Header>
		          <Button className="close txt-white" onClick={handleClose}>
		            {labels["btn"]["close"]} <span aria-hidden="true">&times;</span>
		          </Button>
		        </Modal.Header>
		        <Modal.Body>
		          <Configurator technology={technologyRoot}  />
		        </Modal.Body>
		        {selectedProduct && 
		        <Modal.Footer>
					{selectedProduct && <ProductDetail product={selectedProduct}/> }
		        </Modal.Footer>
		        }
		      </Modal>
			</>,
	container)
}



export const Configurator = (props: any) => {
	const {t, configuratorSetting} = React.useContext(LabelContext);

	const { titleStep, setStepQueue,stepQueue,  setPreviousState, step, setStep, prevStep, setPrevStep, items, step1, setStep1, setStep2, setStep3, setStep4, step5, setStep5, message, technologyRoot} = React.useContext(ConfiguratorContext);

	

	const settings = {
		dots: true,
		centerMode: false,
		centerPadding: '0',
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		responsive: [	
						{	
		                breakpoint: 880,
		                settings: { 
		                        infinite: false,
		                        swipe: true,
		                        slidesToShow: 2,
		                        centerPadding: '35px',
		                        arrows: false, 
								centerMode: false,
		                    }
		                },
		                {
		                breakpoint: 600,
		                settings: { 
		                        infinite: false,
		                        swipe: true,
		                        slidesToShow: 1,
		                        centerPadding: '25px',
		                        arrows: false,
								centerMode: false,
		                    }
		                }
		            ],      
    };

	
	let configurationRoot = configuratorSetting[technologyRoot];
    //let labels = configurationRoot["labels"]["step" + step]

    React.useEffect(
    	() => {
    		let tmp = stepQueue;
    		if(tmp[step]==null && prevStep > 0) {
	    		tmp[step] = prevStep
	    		setStepQueue(tmp)	    			
    		}
    	},[step]
    )


	return (
		<>
		<ConfiguratorStyle>			
	        <div className="progressbar"><div className={"bg-progress " + "step" +  step}></div></div>
	        <div className="title-wrapper">	     
	        	
			    <div className="title" dangerouslySetInnerHTML={{__html: titleStep}}></div>

			        {step > 1 && step <= 5 && <a className="cta-back" onClick={ () => { setPreviousState(); } }
			    		>{configurationRoot["labels"]["btn"]["back"]}</a>
			    	}
			    		 
	       
	        </div>
	    	<Slider {...settings} className="item-container">
	        { items && items.length>0 && items.map((item:any, i:any) => {
	        		let key = Object.keys(item)[0];
	        		
	        		item = item[key];
	        		return (
	        			<Item key={i} ItemKey={key} description={item.description} img={item.img} title={item.title} subtitle={item.subtitle}   />
	        			)
	        	}) 
	        }
	    	</Slider>
        </ConfiguratorStyle>
		</>
	 )
}


export const Item = (props: any) => {
	const {t, configuratorSetting} = React.useContext(LabelContext);
	const { checkChange, setCheckChange, step, setPreviousState, setStep1, setStep2, setStep3, setStep4, setStep5, technologyRoot, setPrevStep } = React.useContext(ConfiguratorContext);
	let configurationLabels = configuratorSetting[technologyRoot]["labels"];
	const [openDescription, setOpenDescription] = React.useState(false)
	return (
		<>
			{configurationLabels &&
			    <ItemStyle className="item-wrap">

			    	<div className={"slider-item " + "step" +  step}>
				    	{props.img &&  <div className="image"  onClick={ () => {
				        	setPrevStep(step)
				        	if(step==1)
				        		setStep1(props.ItemKey);
				        	if(step==2)
				        		setStep2(props.ItemKey);
				        	if(step==3)
				        		setStep3(props.ItemKey);
				        	if(step==4)
				        		setStep4(props.ItemKey);
				        	if(step==5 || step==6)
				        		setStep5(props.ItemKey);
				        	setCheckChange(checkChange + 1)
				        	}}> <img src={props.img} /></div>}

				        <div className="caption" dangerouslySetInnerHTML={{__html: props.subtitle}} />
							<div className="title">
				        {!openDescription &&  
				        	<>
							<span onClick={ () => { if(props.description && step > 1) {setOpenDescription(!openDescription)}}} dangerouslySetInnerHTML={{__html: props.title}} />  
							{props.description && step > 1 && 
							    <a className="linkToPopupDescrition" onClick={ () => setOpenDescription(!openDescription)}>i</a>							
							}
				        	</>
						}
							</div>
						{step == 1 && 
				        	<div className="description-step1" dangerouslySetInnerHTML={{__html: props.description}} />
						}
						{openDescription && 
				        	<div className="description" dangerouslySetInnerHTML={{__html: props.description}} onClick={ () => setOpenDescription(!openDescription)} />
						}
				        <a className="cta" onClick={ () => {
				        	setPrevStep(step)
				        	if(step==1)
				        		setStep1(props.ItemKey);
				        	if(step==2)
				        		setStep2(props.ItemKey);
				        	if(step==3)
				        		setStep3(props.ItemKey);
				        	if(step==4)
				        		setStep4(props.ItemKey);
				        	if(step==5 || step==6)
				        		setStep5(props.ItemKey);
				        	setCheckChange(checkChange + 1)
				        	}


				        }>
				        
				        {configurationLabels["btn"]["select"]}
				        </a>
			    </div>
			    </ItemStyle>
			}
		</>
	)
}


export const ProductDetail = (props: any) => {
	const {t, configuratorSetting} = React.useContext(LabelContext);

	const { step, setPreviousState,setSelectedProduct, setStep1, setStep2, setStep3, setStep4, technologyRoot} = React.useContext(ConfiguratorContext);

	let configurationLabels = configuratorSetting[technologyRoot]["labels"];

	let prodKey:string = Object.keys(props.product)[0];
	
	let detail = props.product;

	setTimeout(function() { 
		document.documentElement.scrollTop = document.documentElement.scrollHeight;
	}, 100);
	

	return (
		<ProductDetailStyle>
		<Container fluid="xxl" className="box-product">
  			<Row className="product-wrap">
  				<Col className="col-image">
	                <div className="image">
	                	{detail?.detail_img &&  <img src={detail?.detail_img} /> }
	                	{!detail?.detail_img &&  <img src={detail?.img} /> }
	                    	
	                </div>
  				</Col>
  				<Col  md="auto" className="product-info">
                	<div className="caption" dangerouslySetInnerHTML={{__html: detail?.detail_caption}} />
                    <div className="title" dangerouslySetInnerHTML={{__html: detail?.detail_title}} />
	                <p dangerouslySetInnerHTML={{__html: detail?.detail_description}} />
	                <p className="info" dangerouslySetInnerHTML={{__html: detail?.detail_info}} />
  				</Col>
  				<Col className="col-action">
                    <div className="actions">
						<button type="button" className="btn-back " onClick={ () => {  setSelectedProduct();}}>
							<span className="icon-btn"></span>{configurationLabels["btn"]["back"]}
			        	</button>
                        <a href={detail?.link} target="_blank" className="cta-brown">{configurationLabels["btn"]["buy"]}</a> 
                       
						<button type="button" className="cta-brown-cancel " onClick={ () => { setSelectedProduct();}}>
							<span className="txt-brown">{configurationLabels["btn"]["cancel"]}</span>
			        	</button>
                    </div>  					
  				</Col>
  			</Row>
		</Container>
		</ProductDetailStyle>
		)
}