import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root') ? document.getElementById('root') : null;
const el = root ? root.getAttribute('data-param') : "x";
const technology = root ? root.getAttribute('data-technology') : null;
const channel = root ? root.getAttribute('channel') : "localhost";



ReactDOM.render(

  <React.StrictMode>
	
    <App technologyStyle={el} technology={technology} channel={channel} />
  	
  </React.StrictMode>,
  document.getElementById('root')
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();