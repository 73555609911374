export const domains:any = 
{
	"localhost":{
		"baseurl" :"//localhost:3000/",
		"folder": "southafrica-en"
	},
	"unitedkingdom":{
		"baseurl" :"./",
		"folder": "unitedkingdom-en"
	},
	"italy":{
		"baseurl" :"./",
		"folder": "italy-it"
	},
	"australia":{
		"baseurl" :"./",
		"folder": "australia-en"
	},
	"international":{
		"baseurl" :"./",
		"folder": "international-en"
	},
	"spain":{
		"baseurl" :"./",
		"folder": "spain-es"
	},
	"sweden":{
		"baseurl" :"./",
		"folder": "sweden-sv"
	},
	"belgium-fr":{
		"baseurl" :"./",
		"folder": "belgium-fr"
	},
	"belgium-nl":{
		"baseurl" :"./",
		"folder": "belgium-nl"
	},
	"france":{
		"baseurl" :"./",
		"folder": "france-fr"
	},
	"germany":{
		"baseurl" :"./",
		"folder": "germany-de"
	},
	"netherlands":{
		"baseurl" :"./",
		"folder": "netherlands-nl"
	},
	"norway":{
		"baseurl" :"./",
		"folder": "norway-nb"
	},
	"poland":{
		"baseurl" :"./",
		"folder": "poland-pl"
	},
	"portugal":{
		"baseurl" :"./",
		"folder": "portugal-pt"
	},
	"russia":{
		"baseurl" :"./",
		"folder": "russia-ru"
	},
	"ukraine-ru":{
		"baseurl" :"./",
		"folder": "ukraine-ru"
	},
	"ukraine-uk":{
		"baseurl" :"./",
		"folder": "ukraine-uk"
	},
	"denmark":{
		"baseurl" :"./",
		"folder": "denmark-da"
	},
	"finland":{
		"baseurl" :"./",
		"folder": "finland-fi"
	},
	"ireland":{
		"baseurl" :"./",
		"folder": "ireland-en"
	},
	"southafrica":{
		"baseurl" :"./",
		"folder": "southafrica-en"
	},
	"newzealand":{
		"baseurl" :"./",
		"folder": "newzealand-en"
	},	
	"greece":{
		"baseurl" :"./",
		"folder": "greece-el"
	},	
	"turkey":{
		"baseurl" :"./",
		"folder": "turkey-tr"
	},
	"singapore":{
		"baseurl" :"./",
		"folder": "singapore-en"
	}
}