import React from "react";
import { ajax } from "rxjs/ajax";
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { domains } from './env';

export const LabelContext = React.createContext<any>(null!);

export const LabelContextComponent = (props: any) => {  
  const [labels, setLabels] = React.useState<any>(null);
  const [configuration, setConfiguration] = React.useState<any>(null);

  
  let host = domains[props.channel]["baseurl"];
  let folder = domains[props.channel]["folder"];

  
  const init = () => {
    let $obs;
    if(props.channel=="localhost"){
      $obs = ajax(host +  'out_structure.json')
    }else{
      $obs = ajax(host + 'binaries/content/assets/galileo/' + folder + '/configurator.json')
    }
    $obs.subscribe((response) => { setConfiguration(response.response);} ) ;
  };



  const t = (key: string) => {
    let result = "???" + key + "???";
    if (labels) {
      let labelFound = labels.find((e: { key: string; }) => e.key === key);
      if (labelFound && labelFound.value !== null && labelFound.value !== undefined) {
        result = labelFound.value;
      }else{
        //result = ""
      }
    }else{
      //result = ""
    }
    return result;
  }

  React.useEffect(() => {
    init();
  }, []);

  return (
    <LabelContext.Provider value={{
      t: t,
      configuratorSetting: configuration
    }}>
      {configuration && props.children}
    </LabelContext.Provider>
  );
};