import styled from "styled-components";

export const ProductDetailStyle = styled.div`
{
    color: #000;
    text-align: left;
    max-height: 60vh;
    position: fixed;
    width: 100%;
    overflow: visible;
    height: auto;
    bottom: 0px;

    @media (max-width:768px){
      height: 80vh;
      bottom: -25px;
      max-height: 80vh;
    }


    .caption, .title{
      font-size:16px;
      
    }
    .cta-brown { background-color:#966e55; color: #fff;  font-size:12px; height:44px;text-transform: uppercase; border:none; min-width: 160px;}
    .cta-brown-cancel { border: 1px solid #966e55; color: #966e55; background-color: #fff;  font-size:12px;  height:44px; text-transform: uppercase; min-width: 160px;}

    @media (min-width:768px){
     
      .image img{
        max-width:300px;   
        width: 100%; 
      }
      
    }

    .box-product {
      overflow: visible;
      height: auto;
      max-height: 80vh;
      background-color: #fff;
      
      width: 100%;
      height: 100%;
      position: relative;

      @media (min-width:768px){
        height: 80vh;
      }
    }

    .product-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding: 60px 10px 100px 10px;
      background: #fff;
      
    }

    @media (max-width:790px){
      .box-product {
        padding: 25px 0;
        padding-bottom: 90px;
      }

      .product-wrap  {
        display: block !important;
        
      }

      .col-action {
        width: 100%;
      }

      .col-image {
        width: 30%;
      }

      @media (max-width:790px){
        .col-image {
          width: 30%;
          margin: 0 auto;
        }
      }

      .col-image img{
        margin-left: 10px;
      }

      @media (max-width:790px){
        .col-image img{
          margin-left: 0;
        }
      }

      .col-action .actions{
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        width: 100%;
      }

      .col-action .actions a{
          width: 50%;
          margin-right: 10px;

          @media (max-width:790px){
            margin: 0 auto;
          }
      }

      .col-action .actions button{
        display: none;
      }
      @media (max-width:790px){
        .col-action .actions .btn-back {
          display: block;
          position: absolute;
          right: 10px;
          top: 10px;
          color: #fff;
        } 
        .col-action .actions .btn-back:after {
          display: block;
          color: #000;
          content: "x";
        }
      }
    }

    .product-info {
      width: 30%;
      margin: 0px 2% 0 4%;
    }

    @media (max-width:790px){
      .product-info {
        width: 100%;
        margin: 0;
        text-align: center;
      }

      .product-info .title {
        font-size: 20px;
        font-weight: normal;
      }
    }

    .title, .caption { text-transform: uppercase; }
    .title { font-size: 30px; font-weight: bold;}
    .caption{ font-size: 18px; padding-top:20px; margin-bottom: 0; }
    .info {font-weight: bold}
    .actions {
      a {
        display: block;
        text-align: center;
        height: 20px;
        padding: 15px 0;
        text-decoration: none;
      }
      .cta-brown {
        margin-bottom: 20px;
      }
    }

    @media (min-width:790px){
      .actions {
        display: flex;
        flex-direction: column;
        align-items: end;
        height: 100%;
        justify-content: flex-end;
      }

      .actions .btn-back {
        margin-bottom: 25px;
      }

      .actions .btn-back:before {
        content: "<";
        display: inline-block; margin-right: 5px;
      }

      .product-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
}
 `;


export const ItemStyle = styled.div`
{
    text-align: center;
    color: #FFF;    
    background: linear-gradient(#000000, #17191C, #000000);
    font-size: 20px;
    margin: 20px 10px;
    padding: 10px 10px;
    height: 100%;
    min-height: 350px;
    min-width: 200px; 

    .slider-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 120px);
    }

    @media (max-width:768px){
      .slider-item {
        font-size: 16px;
      }
      
    }

    .slider-item .image{
      text-align: center;
    }

    .slider-item .image img {
      width: 85%;
      margin: 0 auto;
      cursor: pointer;
    } 

    .title, .caption { text-transform: uppercase; }
    .title { font-size: 30px; font-weight: bold;}
    .caption{ font-size: 22px; padding-top:20px; margin-bottom: 0; min-height:60px; }
    .cta {
          border: 1px solid #FFF;
          background-color: transparent;
          padding: 18px 80px;
          display: inline-block;
          margin: 0 auto;
          text-decoration: none;
          font-size: 14px;
          color: #FFF;
          cursor: pointer;
          text-transform: uppercase;
    }

    
    .slider-item {
        .title { font-size: 30px ; margin-top: 10px; min-height: 70px;}       
        .description { position: absolute;
          bottom: 21%;
          left:0;
          padding: 0 20px;
          border-radius: 10px;
          font-size: 13px;}
        }  
        .description:after {
          content: "x";
          position: absolute;
          top: -15px;
          width: 15px;
          border: 1px solid #fff; 
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          height: 15px;
          line-height: 0;
          right: 20px;
          justify-content: center;
        }
        .linkToPopupDescrition{
          text-transform: none !important;
          background-color: #fff;
          color: black;
          border-radius: 20px;
          padding: 0px 9px;
          font-size: 14px;
          text-align: center;
          margin-left: 12px;
          display: inline-block;
          vertical-align: top;
        }

    @media (max-width:768px){
      .slider-item .title {
        font-size: 25px
      }
      
    }
    
    .slider-item.step1 {
      .title { margin-top: 40px; font-size: 50px; margin-bottom: 40px;  }
      .description-step1 { min-height: 100px;  }
  }
  @media (max-width:768px){
    .slider-item.step1 .title {
      font-size:35px
    }
    
  }
}
`;



export const ConfiguratorStyle = styled.div`
{
    .title {
        font-size: 30px;
        margin: 30px auto;
    }

    @media (max-width:1024px){
      .title{
        font-size: 22px;
        margin: 50px auto;
        margin-bottom: 30px;
      }
    }

    .title-wrapper{
        margin: 0 auto;
        position: relative;
        max-width:870px;

        .cta-back{
            position:absolute;
            top: 8px;
        }

        @media (max-width:1024px){
          .cta-back{
              position:absolute;
              top: -35px;
          }
        }
    }

    .progressbar {
      border: 2px solid #333;
      border-radius: 15px;
      margin: 20px auto;
      height: 5px;
      position: relative;
      max-width: 870px;
      
    }
    @media (max-width:1024px){
      .progressbar{
          margin-bottom: 20px;
      }
    }
    .bg-progress {
        height: 100%;
        background: #FFF;
        width: 25%; 
        border-radius: 15px; 
    }  
    .bg-progress.step2{
            width: 50%;  
    }
    .bg-progress.step3{
            width: 75%;  
    }

    .bg-progress.step4, .bg-progress.step5{
            width: 100%;  
    }

   

    .item-container{
        max-width: 1240px;
        margin: 0 auto;
        padding: 0;
    }

    
}    
`;

